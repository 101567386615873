import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getNotifications = createAsyncThunk('notifications/getNotifications', async () => {
  const response = await axios.get('/api/notification');
  return response.data;
});
 
export const deleteNotification = createAsyncThunk('notifications/deleteNotification', async (notificationID) => {
  const response = await axios.post('/api/notification/delete', { id: notificationID });
  return response.data;
});

export const clearNotifications = createAsyncThunk('notifications/clearNotifications', async () => {
  const response = await axios.post('/api/notification/delete-all');
  return response.data;
});

export const createNotification = createAsyncThunk('notifications/createNotification', async (notification) => {
  const response = await axios.post('/api/notification/add', { notification });
  return response.data;
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(clearNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clearNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default notificationsSlice.reducer;
