import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
// CUSTOM COMPONENT
import { MatxLayoutSettings } from "components/MatxLayout/settings";

const initialState = {
  settings: MatxLayoutSettings,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings: (state, action) => {
      state.settings = merge({}, state.settings, action.payload);
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export const selectSettings = state => state.settings.settings;

export default settingsSlice.reducer;
