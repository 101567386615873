export const CRUD_ENTITIES = {
  USER: {
    namespace: 'user',
  },

  STATUS_CATEGORY: {
    namespace: 'statusCategory',
  },
  PAGE: {
    namespace: 'page',
  },
  EMAIL_TEMPLATE: {
    namespace: 'emailTemplate',
  },
  TESTIMONIAL: {
    namespace: 'testimonial',
  },
  BANNER: {
    namespace: 'banner',
  },
  ADVERTISEMENT: {
    namespace: 'advertisement',
  },
  STATE: {
    namespace: 'state',
  },
  CITY: {
    namespace: 'city',
  },
  VENUE_TYPE: {
    namespace: 'venueType',
  },
  VENUE_FACILITY: {
    namespace: 'venueFacility',
  },

  ABOUT: {
    namespace: 'about',
  },

  ANCILLIARY_SERVICE_CATEGORY: {
    namespace: 'ancilliaryServiceCategory',
  },
  BANK: {
    namespace: 'bank',
  },

  PLAN: {
    namespace: 'plan',
  },

  COUNTRY: {
    namespace: 'country',
  },
  ANCILLARY_SERVICE: {
    namespace: 'ancillaryService',
  },


  








};
