import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginService, registerService ,changePasswordService } from 'services/authService';
import { persistAuthData } from 'utils/utils';

export const loginUser = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const data = await loginService(credentials);
    persistAuthData(data)
    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const registerUser = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
  try {
    const data = await registerService(userData);
    persistAuthData(data)
    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const changePasswordUser = createAsyncThunk('auth/change-password', async (requestedData, { rejectWithValue }) => {
  try {
    const data = await changePasswordService(requestedData);
    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


