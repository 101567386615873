import { createSlice } from '@reduxjs/toolkit';
import { CRUD_STATE } from 'utils/constant';
import { CRUD_ENTITIES } from 'utils/entitiesCrud';
import { pluralize } from 'utils/utils';

/**
 * Utility function to get initial state for an entity
 * @returns {object} - The initial state object for an entity
 */
const getInitialEntityState = () => ({ ...CRUD_STATE });


/**
 * Define the initial state
 * @type {object}
 */
const initialState = Object.values(CRUD_ENTITIES).reduce((acc, entityGroup) => {
  acc[entityGroup.namespace] = getInitialEntityState();
  return acc;
}, {});

/**
 * Handle fulfilled state for listing entities
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handleListFulfilled = (state, { meta: { arg: { entity } }, payload: { response } }) => {
  const entityName = pluralize(entity);
  state[entity] = {
    ...state[entity],
    loading: false,
    status: response?.status,
    count: response?.count,
    list:response[entityName] ,
    error: null,
  };
};

/**
 * Handle fulfilled state for fetching entity details
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handleDetailFulfilled = (state, { meta: { arg: { entity } }, payload: { response } }) => {
  state[entity] = {
    ...state[entity],
    loading: false,
    status: response?.status,
    detail: response[entity],
    error: null,
  };
};

/**
 * Handle fulfilled state for adding an entity
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handleAddFulfilled = (state, { meta: { arg: { entity } }, payload: { response } }) => {
  const newItem = response[entity];
  state[entity] = {
    ...state[entity],
    loading: false,
    status: response?.status,
    list: [newItem, ...state[entity].list],
    add: { count: state[entity].add.count + 1, ...response },
    error: null,
    refetchList :  state[entity].list.length < 1 ? true : false  ,
    count: state[entity].count +  1,
  };
};

/**
 * Handle fulfilled state for editing an entity
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handleEditFulfilled = (state, { meta: { arg: { entity } }, payload: { response } }) => {
  const updatedItem = response[entity];
  state[entity] = {
    ...state[entity],
    loading: false,
    status: response?.status,
    list: response?.status ? state[entity].list.map((item) => (item._id === updatedItem._id ? updatedItem : item)) :  state[entity].list,
    edit: { count: state[entity].edit.count + 1, ...response },
    error: null,
    refetchList :  state[entity].list.length < 1 ? true : false  ,
  };
};

/**
 * Handle fulfilled state for deleting an entity
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handleDeleteFulfilled = (state, { meta: { arg: { entity } }, payload: { response } }) => {
  const deletedItemId = response[entity]?._id;
  state[entity] = {
    ...state[entity],
    loading: false,
    status: response?.status,
    list: response?.status ? state[entity].list.filter((item) => item._id !== deletedItemId) :  state[entity].list,
    del: { count: state[entity].del.count + 1 ,...response },
    error: null,
    count: state[entity].count - 1,

  };
};

/**
 * Handle rejected states for CRUD operations
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handleRejected = (state, { meta: { arg: { entity } }, error }) => {
  state[entity] = {
    ...state[entity],
    loading: false,
    status: false,
    error: error ? error.message : null,
  };
};

/**
 * Handle pending states for CRUD operations
 * @param {object} state - The current state
 * @param {object} action - The action object
 */
const handlePending = (state, { meta: { arg: { entity  ,loading =true } }, error }) => {
 
  state[entity] = {
    ...state[entity],
    loading: loading,
    status: false,
  };
};



/**
 * Create the slice for CRUD operations
 */
const crudSlice = createSlice({
  name: 'crud',
  initialState,
  reducers: {
    clearAddState : (state, { payload: { entity } }) => {
      state[entity] = {
        ...state[entity],
        add: { ...state[entity].add , status : false},

      };
    },
    clearEditState : (state, { payload: { entity } }) => {
      state[entity] = {
        ...state[entity],
        edit: { ...state[entity].edit , status : false},

      };
    },

    clearDetailState : (state, { payload: { entity } }) => {
      state[entity] = {
        ...state[entity],
        detail: { count: 0  , status : false , isList : false},

      };
    },
    clearDeleteState : (state, { payload: { entity } }) => {
      state[entity] = {
        ...state[entity],
        del: { ...state[entity].del , status : false},

      };
      
    },

    refetchListAction : (state, { payload: { entity } }) => {
      state[entity] = {
        ...state[entity],
        refetchList : true ,

      };
      
    },

    
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type.endsWith('/pending') && action.type.startsWith('crud/'),
        handlePending
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected') && action.type.startsWith('crud/'),
        handleRejected
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') && action.type.startsWith('crud/list/'),
        handleListFulfilled
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') && action.type.startsWith('crud/detail/'),
        handleDetailFulfilled
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') && action.type.startsWith('crud/add/'),
        handleAddFulfilled
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') && action.type.startsWith('crud/edit/'),
        handleEditFulfilled
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') && action.type.startsWith('crud/delete/'),
        handleDeleteFulfilled
      )
      .addMatcher(
        (action) => action.type.endsWith('/clear') && action.type.startsWith('crud/'),
        (state, action) => {
          const entity = action.type.split('/')[1];
          state[entity] = getInitialEntityState();
        }
      )
      
      .addMatcher(
        (action) => action.type.endsWith('/resetEntityCrud') && action.type.startsWith('crud/clear'),
        (state, action) => {
          const entity = action.type.split('/')[2];
          state[entity] = getInitialEntityState();
        })
      .addMatcher(
        (action) => action.type.endsWith('/resetEntityCrud') && action.type.startsWith('crud/clear/add'),
        (state, action) => {
          const entity = action.type.split('/')[2];

          console.log()
          state[entity] ={          
            add: { ...state[entity].add , status : false },
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/resetEntityCrud') && action.type.startsWith('crud/clear/edit'),
        (state, action) => {
          const entity = action.type.split('/')[2];
          state[entity] = getInitialEntityState();
        }
      )
  },
});

/**
 * Action to reset entity CRUD state
 * @param {string} entity - The entity namespace
 * @returns {object} - The action object
 */
export const createResetEntityCrud = (entity) => ({
  type: `crud/${entity}/resetEntityCrud`,
});

export const { clearAddState ,clearEditState ,clearDetailState ,clearDeleteState ,refetchListAction} = crudSlice.actions;


export default crudSlice.reducer;

