import { lazy } from "react";
import Loadable from "components/Loadable";
// const ForgotPassword = Loadable(lazy(() => import("pages/sessions/forgot/ForgotPassword")));
// const JwtRegister = Loadable(lazy(() => import("pages/sessions/register/JwtRegister")));
const JwtLogin = Loadable(lazy(() => import("pages/sessions/login/JwtLogin")));


const sessionRoutes = [
  { path: "/session/signin", element: <JwtLogin /> },
  // { path: "/session/signup", element: <JwtRegister /> },
  // { path: "/session/forgot-password", element: <ForgotPassword /> },
];

export default sessionRoutes;
