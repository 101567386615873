// adminService.js
import apiInstance from 'services/ApiService';
import { ApiConfig } from 'config/ApiConfig';
import { STATE_ENTITIES } from 'utils/entities';

export const getDashboardData = async () => {
  const { url, method } = ApiConfig.ADMIN.DASHBOARD;
  const response = await apiInstance({ url, method });
  return { response, entity: STATE_ENTITIES.ADMIN.DASHBOARD };
};
