
export const url = ""
export const REACT_APP_BASE_URL = "/"
// export const REACT_APP_API_BASE_URL ="http://127.0.0.1:8000/api/admin/" // dev URL 
export const REACT_APP_API_BASE_URL ="http://api.staging.venuelog.com/api/admin/"; // live URL
// export const REACT_APP_API_BASE_URL ="http://192.168.29.31:8000/api/admin/" // dev URL 

// export const CLIENT_APP_API_BASE_URL ="http://192.168.29.31:3000"; // local URL




export const APP_NAME = "VenueLog Admin"
export const APP_DEVELOPED_BY = "IDHAYA CREATIVES v1"
export const PORT = "3000"
export const MAP_API_KEY = 'AIzaSyA-VqC_K0bmsIwWRUJ_aCmhcbLaeDP5y48'


export const CLIENT_APP_API_BASE_URL ="http://web.staging.venuelog.com"; // live URL
// export const CLIENT_APP_API_BASE_URL ="http://127.0.0.1:3000"; // local URL
// export const CLIENT_APP_API_BASE_URL ="http://192.168.29.31:3000"; // local URL


// https://maps.googleapis.com/maps/api/place/textsearch/json?query=regions+in+UK&key=AIzaSyBeSEd28jj7f1LpDCjI-ZowhKp1e8z3q4A



// MAP_API_KEY = 'AIzaSyBeSEd28jj7f1LpDCjI-ZowhKp1e8z3q4A'



// http://api.venuelog.com/api/admin/
// http://127.0.0.1:8000/api/admin/

// test