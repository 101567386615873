import React, { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getItemFromStorage } from "utils/storageHelper";
import { createApiThunk } from "store/thunks/apiThunks"; // Assuming this is where apiThunk is defined
import { getDashboardData } from "services/adminService";
import { createResetEntityApi } from "store/features/apiSlice"; // Assuming this is where resetEntityApi is defined
import { StorageConstant } from "constants/CommonConstants";
import { STATE_ENTITIES } from "utils/entities";

export default function AuthGuard({ children }) {
  const isAuthenticated = getItemFromStorage(StorageConstant.is_admin_access_token);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const dashboardRequest = await getDashboardData(); // Assuming getDashboardData returns a promise
        const dynamicThunk = createApiThunk(dashboardRequest.entity);
        dispatch(dynamicThunk(dashboardRequest));
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    if (isAuthenticated) {
      fetchDashboardData();
    }

    return () => {
      if (isAuthenticated) {
        dispatch(createResetEntityApi(STATE_ENTITIES.ADMIN.DASHBOARD));
      }
    };
  }, [dispatch, isAuthenticated]);

  if (isAuthenticated) return <>{children}</>;

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
}
