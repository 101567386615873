import SecureStorage from 'react-secure-storage';
import { isJson } from './utils';


export const setItemToStorage = (key, value) => {
  SecureStorage.setItem(key, JSON.stringify((value)));
}

export const getItemFromStorage = (key) => {
  const val = SecureStorage.getItem(key);
  return isJson(val)? JSON.parse(val): val;
}

export const removeItemFromStorage = (key) => {
  return SecureStorage.removeItem(key);
}