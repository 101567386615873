export const CODE = {
    OK_CODE: 200,
    CREATED: 201,
    BAD_REQUEST_CODE: 400,
    UNAUTHORIZED_CODE: 401,
    VALIDATION_CODE: 422,
    NO_CONTENT: 204,
    INTERNAL_SERVER_ERROR: 500
}

export const HTTP_METHODS = {
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    GET: "GET",
    DELETE: "DELETE",
  };
  
export const VALIDATION_MESSAGE = {
    CATCH_ERROR: "Oops, something went wrong. Please try again later.",

    NO_SPACE_ALLOWED: "No space allowed.",
    EMAIL_REQUIRED: "Please, enter email address.",
    VALID_EMAIL: "Entered email address is invalid, please enter valid email address",
    ROLL_NAME_REQUIRED: "Please enter roll name.",
    ROLL_NAME_MAX: "Roll name should not exceed more than 25 characters",
    ROLL_NAME_VALID: "The roll name should be in alphabet characters.",
    ROLE_ID_REQUIRED: "Please choose a role.",
    TITLE_REQUIRED: "Please enter ambience title.",
    TITLE_MAX: "Title should not exceed more than 25 characters",
    TITLE_VALID: "The title name should be in alphanumeric characters.",
    FILE_REQUIRED: "Please choose an audio file using .mp3 format.",
    ICON_REQUIRED: "Please choose an icon using .jpeg, .png, .jpg format.",
    BANNER_IMAGE_SIZE: "Image resolution is large, please use image of 2MB in size.",
    BIG_BANNER_IMAGE_SIZE: "Image resolution is large, please use image of 5MB in size.",
    WEB_BANNER_IMAGE_SIZE: "Image resolution is large, please use image of 2MB in size",
    BIG_WEB_BANNER_IMAGE_SIZE: "Image resolution is large, please use image of 5MB in size",
    THUMBAIL_IMAGE_SIZE: "Image resolution is large, please use image of 500KB in size",
    CHANNEL_IMAGE_SIZE: "Image resolution is large, please use image of 2MB in size",
    AUDIO_IMAGE_SIZE: "Image resolution is large, please use image of 2MB in size.",
    AUDIO_FILE_SIZE: "File size should not exceed 500MB.",
    FULL_NAME_REQUIRED: "Please enter your full name.",
    FULL_NAME_MAX: "Should not exceed more than 40 characters",
    FULL_NAME_VALID: "The full name should be in alphabets characters.",
    LAST_NAME_REQUIRED: "Please enter last name.",
    LAST_NAME_MAX: "Should not exceed more than 40 characters",
    LAST_NAME_VALID: "The last name should be in alphabet characters.",
    COMPANY_NAME_REQUIRED: "Please enter company name.",
    COMPANY_NAME_VALID: "The company name should be in alphanumeric characters.",
    COMPANY_NAME_MAX: "Should not exceed more than 40 characters",

    COUNTRY_CODE_REQUIRED: "Please choose country code.",
    MOBILE_NUMBER_REQUIRED: "Please enter mobile number.",
    PHONE_NUMBER_VALID: "Entered mobile number is invalid, please try again.",
    MOBILE_NUMBER_DIGIT: "Mobile number must be 10 digit.",
    MOBILE_NUMBER_MIN: "Mobile number must be between 9 to 16 digit.",
    MOBILE_NUMBER_MAX: "Mobile number must be between 9 to 16 digit.",
    PAN_CARD_NUMBER_REQUIRED: "Enter Pan card number.",
    DRUG_LICENSE_REQUIRED: "Select Drug License.",
    PAN_CARD_NUMBER_VALID: "Please enter a valid Pan Card Number.",
    GST_NUMBER_REQUIRED: "Enter GST number.",
    GST_NUMBER_VALID: "Please enter a valid GST Number.",
    TERMS_CONDITIONS_REQUIRED: "Terms & Conditions is required.",

    PASSWORD_VALID: "Password must contain alphanumeric characters that includes atleast 1 upper case character and 1 special character.",
    PASSWORD_UPPER_CASE: "Password must contain alphanumeric characters that includes atleast 1 upper case character and 1 special character.",
    PASSWORD_SPECIAL_CASE: "Password must contain alphanumeric characters that includes atleast 1 uppercase, 1 lowercase and 1 special character.",
    PASSWORD_NUMBER_CASE: "Password must contain alphanumeric characters that includes atleast 1 upper case character and 1 special character.",

    PASSWORD_REQUIRED: "Please enter password.",
    PASSWORD_MIN_CHAR: "Password must have at least 8 characters.",
    PASSWORD_MAX_CHAR: "Password should not exceed 16 characters",

    CONFIRM_PASSWORD_REQUIRED: "Re-enter password.",
    CONFIRM_PASSWORD_MIN_CHAR: "Confirm password must have at least 8 characters.",
    CONFIRM_PASSWORD_MAX_CHAR: "Confirm Password should not exceed 16 characters",

    OLD_PASSWORD_REQUIRED: "Enter old password.",
    OLD_PASSWORD_MIN_CHAR: "Old password must have at least 8 characters.",
    OLD_PASSWORD_MAX_CHAR: "Old Password should not exceed 16 characters.",

    NEW_PASSWORD_REQUIRED: "Enter new password.",
    NEW_PASSWORD_MIN_CHAR: "New password must have at least 8 characters.",
    NEW_PASSWORD_MAX_CHAR: "New Password should not exceed 16 characters",

    CONFIRM_NEW_PASSWORD_REQUIRED: "Enter confirm password.",
    CONFIRM_NEW_PASSWORD_MIN_CHAR: "Re-enter password must have at least 8 characters.",
    CONFIRM_NEW_PASSWORD_MAX_CHAR: "Re-enter Password should not exceed 16 characters.",
    PASSWORD_CPWD_NOT_MATCHED: "Entered password doesn't match. please try again",
    PASSWORD_OLD_NOT_SAME: "You have already use this password before, please set another password",

    ADDRESS_NAME_REQUIRED: "Enter Name.",
    ADDRESS_NAME_MAX: "Name must be at most 25 characters",
    ADDRESS_NAME_VALID: "The name must contain alpha characters only.",
    ADDRESS_COMPANY_NAME_REQUIRED: "Enter Company Name.",
    ADDRESS_SHOP_NUMBER_REQUIRED: "Enter Shop Number.",
    ADDRESS_BUILDING_NAME_REQUIRED: "Enter Building Name.",
    ADDRESS_STREET_NUMBER_REQUIRED: "Enter Street Number.",
    ADDRESS_ADDRESS_LINE_ONE_REQUIRED: "Enter Address Line - 1.",
    ADDRESS_ADDRESS_LINE_TWO_REQUIRED: "Enter Address Line - 2.",
    ADDRESS_STATE_REQUIRED: "Enter State.",
    ADDRESS_TOWN_CITY_DISTRICT_REQUIRED: "Enter Town / City / District.",
    ADDRESS_PIN_CODE_REQUIRED: "Enter Pin Code.",
    ADDRESS_PIN_CODE_VALID: "Please enter a valid pin code.",
    ADDRESS_PIN_CODE_DIGIT: "Pin code must be 6 digit.",
    ADDRESS_PIN_CODE_MIN: "Pin code must be at least 6 digit.",
    ADDRESS_PIN_CODE_MAX: "Pin code must be at most 6 digit.",

    ADDRESS_DELETE_TITLE: "Are you sure?",
    ADDRESS_DELETE_TEXT: "Are you sure you want to delete this item?",

    REMOVE_PROFILE_IMAGE: "Are you sure you want to remove profile image?",

    DOCUMEMNT_UNSUPPORTED: "Selected file format does not support. please choose correct format.",
    USER_UNSUPPORTED: "File format does not support.",
    USER_IMAGE:"Image Size is not valid.",

    LOGOUT_MODEL_TITLE: "Are you sure?",
    LOGOUT_MODEL_TEXT: "Are you sure you want to logout?",

    ANY_CHOOSE: "Please choose a",
    ANY_SELECT: "Please select ",
    ANY_REQUIRED: "Please select",
    ANY_ENTER:"Please enter",
    ANY_INSERT:"Please insert",
    // ANY_MAX: " must be at most 25 characters",
    ANY_VALID: " must contain alpha characters only.",
    ANY_NUMBER: " must contain number only.",
    ANY_NAME: "Please enter ",


    ARE_YOU_SURE: "Are you sure",
    ARE_YOU_DELETE_ANY: "You want to delete this ",
    ARE_YOU_PREMIUM_ANY: "You want to change the story status",
    ARE_YOU_VERIFY_EMAIL: "Are you sure you want to verify email for this user?",
    ARE_YOU_CHANGE_EMAIL_ANY: "You want to change this email id",

    ARE_YOU_CONVERT_ANY: "You want to add email",
    ARE_YOU_LOGOUT_ANY: "You want to",
    ANY_MAX: " must be less than ",
    ANY_MIN: " must be more than  ",
    EMAIL_MAX: " must be more then ",


    // EXPORT_MESSAGE: "are exporting. Please wait.. and check 'Download Reports' section shortly. (it will take approx 30 min)"

}

export const StorageConstant = {
    is_admin_access_token: "is_admin_access_token",
    is_admin_refresh_token: "is_admin_refresh_token",
    session_info: "session_info",
    remember_me: "remember_me",
    user_password: "user_password",
    user_email: "user_email",
    is_admin_timezone : "is_admin_timezone"
}
export const ProjectConstant = {
    amountLength: 10,
}

export const TIME_ZONE = [
    { name: "Pacific Time (PT)", code: "PST/PDT" },
    { name: "Mountain Time (MT)", code: "MST/MDT" },
    { name: "Central Time (CT)", code: "CST/CDT" },
    { name: "Eastern Time (ET)", code: "EST/EDT" },
    { name: "Alaska Time (AKT)", code: "AKST/AKDT" },
    { name: "Hawaii-Aleutian Time (HAT)", code: "HST/HDT" },
    // Add more time zones as needed
];


export const TICKET_TYPE = [
    { title: "Paid", id: "2" },
    { title: "Free", id: "1" },
    ];


    export const TICKET_VISIBILITY = [
        { title: "Visible", id: "1" },
        { title: "Hidden", id: "0" },

        ];

     
