import { MatxSuspense } from "components";
import { selectSettings } from "store/features/settingsSlice";
import { useSelector,  } from "react-redux";
import { MatxLayouts } from "./index";

export default function MatxLayout(props) {
  const settings = useSelector(selectSettings);
  const Layout = MatxLayouts[settings.activeLayout];

  return (
    <MatxSuspense>
      <Layout {...props} />
    </MatxSuspense>
  );
}
