import momentTimezone from 'moment-timezone';
import secureLocalStorage from "react-secure-storage";

import { DATE, TIME ,DATETIME } from 'constants/DateTimeConstant';
import moment from 'moment';

export const getTimeZoneFromStorage = () => {
    const val = secureLocalStorage.getItem("is_admin_timezone");
    return val
  }

// GET CURRENT TIMEZONE
export const getAdminTimezone = ()  => {
    const timezone = getTimeZoneFromStorage()
    return JSON.parse(timezone);
  };


  // 
  export const getBrowserTimezone = ()  => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };  


// GET ALL TIMEZONES
export const allTimezones = momentTimezone.tz.names();

// GET ALL TIMEZONES WITH PROPERTIES
export function getAllTimezonesWithProperties() {
    const allTimezones = momentTimezone.tz.names();
    
    return allTimezones.map(function(timezone) {
      const tzInfo = momentTimezone.tz(timezone);
      const offsetMinutes = tzInfo.utcOffset();
      const offset = `${offsetMinutes >= 0 ? '+' : '-'}${Math.abs(Math.floor(offsetMinutes / 60)).toString().padStart(2, '0')}:${(offsetMinutes % 60).toString().padStart(2, '0')}`;
      
      return {
        name: timezone,
        offset: offset,
        abbreviation: tzInfo.format('z'),
        dstOffset: tzInfo.format('ZZ'),
        dstStart: tzInfo.clone().startOf('year').format(),
        dstEnd: tzInfo.clone().endOf('year').format(),
        rawOffset: offsetMinutes,
        rawFormat: offset,
        currentTime: tzInfo.format(),
        isDST: tzInfo.isDST(),
        value: timezone,
        label: `${timezone} (${tzInfo.format('z')}) (${offset}) `
      };
    });
  }



// CHECK TIMEZONE IS VALID
export const isValidTimezone = momentTimezone.tz.names().includes('America/New_York');


// Helper function to check if a given time is during Daylight Saving Time (DST) for a specific timezone
export const isDaylightSavingTime = (time, timezone) => {
    // Check if the given time is during DST in the specified timezone
    const isDST = momentTimezone.tz(time, timezone).isDST();

//     // Example usage
// const time = '2024-06-19T12:00:00'; // Example time
// const timezone = 'America/New_York'; // Example timezone

// const duringDST = isDaylightSavingTime(time, timezone);
// console.log(`Is the time ${time} during Daylight Saving Time in ${timezone}? ${duringDST ? 'Yes' : 'No'}`);

    return isDST;
}


// Helper function to convert time between two timezones
export const convertTimeBetweenTimezones = (time, fromTimezone, toTimezone) => {
    // Convert the time from the source timezone to UTC
    const utcTime = momentTimezone.tz(time, fromTimezone).utc();

    // Convert the UTC time to the target timezone
    const convertedTime = utcTime.clone().tz(toTimezone);

    // Format the converted time as desired
    const formattedTime = convertedTime.format('YYYY-MM-DD HH:mm:ss');

    return formattedTime;
}


export const DateFormat = (date)=>{
    const formattedDate = moment(date).format("MM-DD-YYYY");
    return formattedDate;
}
export const YearFormats = (date)=>{
    const formattedDate = moment(date).format("YYYY");
    return formattedDate;
}

  




// Helper function to set date without time
export const setDate = (date) => {
    return momentTimezone.utc(date);
};

// Helper function to set date and time
export const setDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    return momentTimezone.utc(dateTimeString);
};

export const setTime = (time) => {
    return momentTimezone.utc(time , TIME.FULL_TIME);
    
};



export const expectedFormatRegex = /^\d{2}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/; // Expected date format pattern



export const adjustTimeForDST = (selectedDate) => {
    const timeZone = getAdminTimezone();
    const currentIsDST = momentTimezone()?.tz(timeZone)?.isDST();
    const selectedIsDST = momentTimezone(selectedDate)?.tz(timeZone)?.isDST();
    const currentOffset = momentTimezone()?.tz(timeZone)?.utcOffset();
    const selectedOffset = momentTimezone(selectedDate)?.tz(timeZone)?.utcOffset();

    // console.log("currentIsDST " ,currentIsDST , selectedIsDST)


    if (!currentIsDST && !selectedIsDST) {
        return false; // Both dates are not in DST, no adjustment needed
    }
    if (currentIsDST && selectedIsDST) {
        return false;
    }
    if (currentIsDST && !selectedIsDST) {
        if (currentOffset && selectedOffset &&currentOffset > Number(selectedOffset)) {
            // Subtract 1 hour from the time
            return -1;
        } else {
            // No adjustment needed
            return false;
        }
    } else if (!currentIsDST && selectedIsDST) {
        if (currentOffset && selectedOffset && Number(currentOffset) < Number(selectedOffset)) {
            // Add 1 hour to the time
            return 1;
        } else {
            // No adjustment needed
            return false;
        }
    } else {
        return false; // Both dates are in DST, no adjustment needed
    }
}

export const adjustTimeForDSTV2 = (selectedDate) => {
    const timeZone = getAdminTimezone();
    const currentIsDST  = momentTimezone.tz(timeZone)?.isDST();
    const selectedIsDST  = momentTimezone.tz(selectedDate, timeZone)?.isDST();
    const currentOffset = momentTimezone.tz(timeZone)?.utcOffset();
    const selectedOffset = momentTimezone.tz(selectedDate, timeZone)?.utcOffset();

    // console.log("timeZone" ,timeZone,"currentIsDST" ,currentIsDST,"selectedIsDST" ,selectedIsDST,"currentOffset" ,currentOffset,"selectedOffset" ,selectedOffset)

    if (!currentIsDST && !selectedIsDST) {
        console.log("no timzezone diff")
        return false; // Both dates are not in DST, no adjustment needed
    }
    if (currentIsDST !== selectedIsDST) {
        // One date is in DST while the other is not
        const hourDifference = (selectedOffset - currentOffset) / 60; // Convert minutes to hours
        return currentIsDST ? -hourDifference : hourDifference;
    }
    return false; // Both dates are either in DST or not, no adjustment needed
}




// Helper function to adjust the scheduled time for DST changes
export const adjustScheduledDateForDST = (scheduledTime, format =  DATE.DEFAULT_DATE) => {
    // if(!scheduledTime.isValid()){
    //     return
    // }

    if(!scheduledTime || !moment(scheduledTime).isValid()){
        return
    }

    const dateFormat = format
    const adjustedTime = adjustTimeForDST(scheduledTime);
    const userTimeZone = getAdminTimezone() // Guess the user's timezone

    if (typeof adjustedTime === 'number') {
        console.log("non dst")
        // Adjustment needed
        const adjustedScheduledTime = momentTimezone.tz(scheduledTime, userTimeZone).add(adjustedTime, 'hours').format(dateFormat);
        return adjustedScheduledTime
        // No adjustment needed
      
    }else{
        // console.log("non dst" ,scheduledTime.format("LL") ,userTimeZone)

        const adjustedScheduledTime = momentTimezone.tz(scheduledTime, userTimeZone).format(dateFormat);
        // const adjustedScheduledTime = scheduledTime.format(dateFormat);

        return adjustedScheduledTime
    }
};;


// Helper function to adjust the scheduled time for DST changes
export const adjustScheduledTimeForDST = (scheduledTime, format =  TIME.DEFAULT_TIME) => {
    if(!scheduledTime?.isValid()){
        return
    }
    const dateFormat = format
    const adjustedTime = adjustTimeForDST(scheduledTime);
    const userTimeZone = getAdminTimezone() // Guess the user's timezone

    if (typeof adjustedTime === 'number') {
        // Adjustment needed
        const adjustedScheduledTime = momentTimezone.tz(scheduledTime, userTimeZone).add(adjustedTime, 'hours').format(dateFormat);
        return adjustedScheduledTime
        // No adjustment needed
      
    }else{
        const adjustedScheduledTime = momentTimezone.tz(scheduledTime, userTimeZone).format(dateFormat);
        return adjustedScheduledTime
    }
};


// Helper function to adjust the scheduled time for DST changes
export const adjustScheduledDateTimeForDST = (scheduledTime, format =  DATETIME.LONG_DATE_TIME) => {
    if(!scheduledTime?.isValid()){
        return
    }
    const dateFormat = format
    const adjustedTime = adjustTimeForDST(scheduledTime);
    const userTimeZone = getAdminTimezone() // Guess the user's timezone

    if (typeof adjustedTime === 'number') {
        // Adjustment needed
        const adjustedScheduledTime = momentTimezone.tz(scheduledTime, userTimeZone).add(adjustedTime, 'hours')
        return adjustedScheduledTime
        // No adjustment needed
      
    }else{
        const adjustedScheduledTime = momentTimezone.tz(scheduledTime, userTimeZone)
        return adjustedScheduledTime
    }
};



export const getEventStatus = (row) => {
    const { start_date, end_date, start_time, end_time } = row;
    const userTimeZone = getAdminTimezone(); // Guess the user's timezone
    const currentDate = adjustScheduledDateTimeForDST(momentTimezone().tz(userTimeZone))
    const startDate = adjustScheduledDateTimeForDST(setDateTime(start_date,start_time).tz(userTimeZone))
    const endDate = adjustScheduledDateTimeForDST(setDateTime(end_date,end_time).tz(userTimeZone))


    // Compare dates to determine the event status
    if (startDate.isBefore(currentDate) && endDate.isAfter(currentDate)) {
        return "Live";
    } else if (endDate.isBefore(currentDate)) {
        return "Past";
    } else {
        return "Upcoming";
    }
};







export const defaultDate = (date) => {
    return moment(date).format(DATE.DEFAULT_DATE);
}


export const defaultTime = (time) => {
    return moment(time).format(TIME.DEFAULT_TIME);  
}

export const defaultDateTime = (date , time) => {
    const dateTimeString = `${date} ${time}`;
    return moment(dateTimeString).format(DATETIME.DEFAULT_DATETIME);
}
