import AuthGuard from "auth/AuthGuard";
import MatxLayout from "components/MatxLayout/MatxLayout";
import sessionRoutes from "routes/session-routes";
import Redirect from "pages/redirect/Redirect";
import privateRoutes from "./private-routes";
const routes = [
  {
    element: (
      <AuthGuard >
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...privateRoutes ]
  },

  // // session pages route
  ...sessionRoutes ,
  { path: "/", element: <Redirect /> },
];

export default routes;
