import { CssBaseline, ThemeProvider } from "@mui/material";
import { selectSettings } from "store/features/settingsSlice";
import React from "react";

import { useSelector,  } from "react-redux";
const MatxTheme = ({ children }) => {
  const settings = useSelector(selectSettings);
  let activeTheme = { ...settings.themes[settings.activeTheme]};
  

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
