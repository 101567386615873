import { createSlice } from "@reduxjs/toolkit";
import { STATE_ENTITIES } from "utils/entities";

// Utility function to get initial state for an entity
const getInitialEntityState = () => ({
  loading: false,
  status: false,
  data: null,
  error: null,
});

// Define the initial state
const initialState = Object.values(STATE_ENTITIES).reduce((acc, entityGroup) => {
  Object.values(entityGroup).forEach((entity) => {
    acc[entity] = getInitialEntityState();
  });
  return acc;
}, {});

// Create the slice
const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    // No specific reducers defined in this example
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type.endsWith('/pending') && action.type.startsWith('api/'),
        (state, action) => {
          const { entity } = action.meta.arg;
          state[entity] = {
            ...getInitialEntityState(),
            loading: true,
          };
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') && action.type.startsWith('api/'),
        (state, action) => {
          const { entity } = action.meta.arg;

          state[entity] = {
            ...state[entity],
            loading: false,
            status: action?.payload?.response?.status,
            data: action?.payload?.response,
            error: null,
          };
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected') && action.type.startsWith('api/'),
        (state, action) => {
          const { entity } = action.meta.arg;
          state[entity] = {
            ...state[entity],
            loading: false,
            status: action?.payload?.response?.status,
            data: null,
            error: action?.payload,
          };
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/resetEntityApi') && action.type.startsWith('api/'),
        (state, action) => {
          const entity = action.type.split('/')[1];
          state[entity] = getInitialEntityState();
        }
      );
  },
});

// Action creator for dynamic resetEntityApi
export const createResetEntityApi = (entity) => ({
  type: `api/${entity}/resetEntityApi`,
});

export default apiSlice.reducer;
