import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import settingsReducer from './features/settingsSlice';
import notificationsReducer from './features/notificationsSlice';
import loaderReducer from './features/loaderSlice';
import snackBarReducer from './features/snackBarSlice';
import apiReducer from './features/apiSlice';
import crudReducer from './features/crudSlice';

/**
 * Configures the Redux store with slices for different features.
 * @returns {object} - The configured Redux store.
 */
export const store = configureStore({
  reducer: {
    crud: crudReducer,

    auth: authReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    loader: loaderReducer,
    snackBar: snackBarReducer,
    api: apiReducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

