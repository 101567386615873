import { StorageConstant } from "constants/CommonConstants";
import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { getItemFromStorage } from "utils/storageHelper";


export default function Redirect() {
  // const auth = useSelector((state) => state.auth);
  const isAuthenticated = getItemFromStorage(StorageConstant.is_admin_access_token);
  console.log("🚀 ~ Redirect ~ isAuthenticated:", isAuthenticated)
  const { pathname } = useLocation();

  if (isAuthenticated) return <Navigate replace to="/dashboard" state={{ from: pathname }}   />;

  return <Navigate replace to="/session/signin" state={{ from: pathname }}   />;
}
