// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { hideSnackbar } from 'store/features/snackBarSlice';
// import { Alert, Snackbar, Slide } from '@mui/material';

// const SnackbarComponent = () => {
//   const dispatch = useDispatch();
//   const snackbar = useSelector((state) => state.snackBar);

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     dispatch(hideSnackbar());
//   };

//   useEffect(() => {
//     // Automatically hide Snackbar after 6 seconds
//     let timer;
//     if (snackbar.open) {
//       timer = setTimeout(() => {
//         dispatch(hideSnackbar());
//       }, 1000); // Adjust the duration as needed (in milliseconds)
//     }
//     return () => {
//       clearTimeout(timer);
//     };
//   }, [snackbar.open, dispatch]);

//   return (
//     <Snackbar
//       open={snackbar.open}
//       autoHideDuration={1000} // Adjust the duration as needed (in milliseconds)
//       onClose={handleClose}
//       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       TransitionComponent={Slide} // Slide transition effect
//       TransitionProps={{ direction: 'down' }} // Slide direction
//     >
//       <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled">
//         {snackbar.message}
//       </Alert>
//     </Snackbar>
//   );
// };

// export default SnackbarComponent;

// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { hideSnackbar } from 'store/features/snackBarSlice';
// import { SnackbarProvider, useSnackbar } from 'notistack';
// import { Alert, Snackbar, Slide } from '@mui/material';

// const SnackbarComponent = () => {
//   const dispatch = useDispatch();
//   const { enqueueSnackbar } = useSnackbar();
//   const snackbar = useSelector((state) => state.snackBar);

//   useEffect(() => {
//     // Function to show snackbar using notistack
//     const showNotistackSnackbar = () => {
//       enqueueSnackbar(snackbar.message, { variant: snackbar.severity });
//     };

//     // Check if snackbar is open and enqueue with notistack
//     if (snackbar.open) {
//       showNotistackSnackbar();
//       // Automatically hide Snackbar after 6 seconds (adjust as needed)
//       const timer = setTimeout(() => {
//         dispatch(hideSnackbar());
//       }, 1000);

//       return () => clearTimeout(timer); // Cleanup timer on unmount
//     }
//   }, [dispatch, enqueueSnackbar, snackbar.open, snackbar.message, snackbar.severity]);

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     dispatch(hideSnackbar());
//   };

//   return (
//     <SnackbarProvider maxSnack={3}>
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={1000} // Adjust the duration as needed (in milliseconds)
//         onClose={handleClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//         TransitionComponent={Slide} // Slide transition effect
//         TransitionProps={{ direction: 'down' }} // Slide direction
//       >
//         <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled">
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </SnackbarProvider>
//   );
// };

// export default SnackbarComponent;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackbar } from 'store/features/snackBarSlice';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Alert, Snackbar, Slide } from '@mui/material';
import { SNACKBAR } from 'utils/constant';

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = useSelector((state) => state.snackBar);

  useEffect(() => {
    const showNotistackSnackbar = () => {
      enqueueSnackbar(snackbar.message, { variant: snackbar.severity });
    };

    if (snackbar.open) {
      showNotistackSnackbar();

      // Automatically hide Snackbar after 6 seconds (adjust as needed)
      const timer = setTimeout(() => {
        dispatch(hideSnackbar());
      }, SNACKBAR.TIMEOUT);

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [dispatch, enqueueSnackbar, snackbar.open, snackbar.message, snackbar.severity]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnackbar());
  };

  return (
    <SnackbarProvider maxSnack={3}>
      {/* Snackbar component for MUI Snackbar (if needed separately) */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={SNACKBAR.TIMEOUT} // Adjust the duration as needed (in milliseconds)
        onClose={handleClose}
        anchorOrigin={SNACKBAR.DIRECTION}
        TransitionComponent={Slide} // Slide transition effect
        TransitionProps={SNACKBAR.TRANSITION} // Slide direction
      >
        <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarProvider>
  );
};

export default SnackbarComponent;
