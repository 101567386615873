// apiThunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';

// Action creator for creating async thunk
export const createApiThunk = (entity,api , payload ,extraPayload) => createAsyncThunk(`api/${entity}`, async ({ response }, { rejectWithValue }) => {
  try {
    const data = await api(payload,extraPayload); // Assuming response is a promise
    return { ...data, entity }; // Successful fetch
  } catch (error) {
    return rejectWithValue({ ...error.response.data, entity }); // Error occurred
  }
});


