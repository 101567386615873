import apiInstance from 'services/ApiService';
import { ApiConfig } from 'config/ApiConfig';

/**
 * Adds the toastStatus to the data object.
 * 
 * @param {Object} data - The data object to which the toastStatus should be added.
 * @param {string} toastStatus - The toastStatus to be added.
 * @returns {Object} - The modified data object with toastStatus included.
 */
const appendToastStatus = (data, toastStatus) => {
  return { ...data, toastStatus };
};

/**
 * Service to handle user login.
 * 
 * @param {Object} credentials - The login credentials (username, password, etc.)
 * @returns {Promise} - The API response
 */
export const loginService = async (credentials) => {
  const { url, method, toastStatus } = ApiConfig.AUTH.LOGIN;
  const requestData = appendToastStatus(credentials, toastStatus);
  
  const response = await apiInstance({
    url, 
    method, 
    data: requestData
  });
  
  return response;
};

/**
 * Service to handle user registration.
 * 
 * @param {Object} userData - The user data for registration
 * @returns {Promise} - The API response
 */
export const registerService = async (userData) => {
  const { url, method, toastStatus } = ApiConfig.AUTH.REGISTER;
  const requestData = appendToastStatus(userData, toastStatus);
  
  const response = await apiInstance({
    url, 
    method, 
    data: requestData
  });
  
  return response;
};

/**
 * Service to refresh the authentication token.
 * 
 * @returns {Promise} - The API response
 */
export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  
  const response = await apiInstance.post('refresh-token-url', { refreshToken }); // Adjust with actual refresh token endpoint
  
  return response;
};





/**
/**
 * Reset the user's password.
 *
 * @param {Object} changePasswordPayload - The data for the password reset request, including new password details.
 * @param {Object} query - Query parameters, including the token.
 * @returns {Promise} - The API response
 */
export const changePasswordService = async (changePasswordPayload) => {
  const { url, method, toastStatus } = ApiConfig.AUTH.CHANGE_PASSWORD;
  const requestData = appendToastStatus(changePasswordPayload, toastStatus);
  
  const response = await apiInstance({
    url,
    method,
    data: requestData,
  });
  
  return response;
};